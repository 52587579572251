import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  USDRates: any;
  EURRates: any;
  JODRates: any;
  EGPRates: any;
  goldRates:any;
  constructor(private http: HttpClient) { }

  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  fetchExchangeRates(): void {
    // Replace 'YOUR_API_KEY' with your API keyhttps://v6.exchangerate-api.com/v6/11e06189063c65ff0614bd1b/latest/EUR
    const apiUrl1 = 'https://v6.exchangerate-api.com/v6/11e06189063c65ff0614bd1b/latest/USD';
    const apiUrl2 = 'https://v6.exchangerate-api.com/v6/11e06189063c65ff0614bd1b/latest/EUR';
    const apiUrl3 = 'https://v6.exchangerate-api.com/v6/11e06189063c65ff0614bd1b/latest/JOD';
    const apiUrl4 = 'https://v6.exchangerate-api.com/v6/11e06189063c65ff0614bd1b/latest/EGP';
    this.http.get<any>(apiUrl1).subscribe(data => {
      const rates = data.conversion_rates;
      let USDRates  = rates["ILS"];
      localStorage.setItem("USD", USDRates);
     
    });
    this.http.get<any>(apiUrl2).subscribe(data => {
      const rates = data.conversion_rates;
     let EURRates  = rates["ILS"];
      localStorage.setItem("EUR",EURRates);
    });
    this.http.get<any>(apiUrl3).subscribe(data => {
      const rates = data.conversion_rates;
      let JODRates  = rates["ILS"];
      localStorage.setItem("JOD",JODRates);
    });
    this.http.get<any>(apiUrl4).subscribe(data => {
      const rates = data.conversion_rates;
      let EGPRates  = rates["ILS"];
      localStorage.setItem("EGP",EGPRates);
    });
  }
}
