import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
 
import { MainService } from 'src/app/_services/main.service';
interface NewsItem {
  title: string;
  content: string;
  date: Date;
}
@Component({
  selector: 'app-breaking-news',
   
  templateUrl: './breaking-news.component.html',
  styleUrl: './breaking-news.component.scss'
})

export class BreakingNewsComponent implements OnInit , AfterViewInit{
  @ViewChild('ticker', { static: false }) ticker: ElementRef;
  @ViewChild('breakingNewsVertical') breakingNewsVertical: ElementRef;

  animationStyle = '';
  breakingNewsItems: string[] =[];
  
  newsItems: NewsItem[] = [
    {
      title: 'Breaking News 1',
      content: 'This is the content of breaking news 1.',
      date: new Date()
    },
    {
      title: 'Breaking News 2',
      content: 'This is the content of breaking news 2.',
      date: new Date()
    },
    {
      title: 'Breaking News 3',
      content: 'This is the content of breaking news 3.',
      date: new Date()
    }
  ];

  constructor(private mainService: MainService ){
    
  }

  ngOnInit(): void {
      this. loadBreakingNews();
  
  }
  readMore(newsItem: NewsItem): void {
    // Handle the read more action
    console.log('Read more:', newsItem);
  }
  loadBreakingNews(): void {
    this.mainService.getImportantNews().subscribe(
      data => {
        const dataReturned = JSON.parse(JSON.stringify(data));
        //console.log("this.breakingNewsItems", dataReturned);
        //this.breakingNewsItems = dataReturned.filter((item: any) => item.Show === 1).map((item: any) => item.ImportantNewsContent);
        this.breakingNewsItems = dataReturned
        .filter((item: any) => item.Show === 1)
        .map((item: any) => {
          // Extracting just the time from InsertDate without seconds
          const dateTime = new Date(item.InsertDate);
          const hours = dateTime.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
          const minutes = dateTime.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed
          const time = `${hours}:${minutes}`; // Format time as HH:MM
          return {
            content: item.ImportantNewsContent,
            time: time,
          };
        });
    

        // Set animation after breaking news items are loaded
        //this.setAnimation();
      },
      error => {
        // Handle errors
        console.log("this.breakingNewsItems error", error);
      }
    );
  }

  ngAfterViewInit(): void {
    
    //this.setAnimation();
  }
 
  setAnimation(): void {
    debugger;
    const itemCount = this.breakingNewsItems.length;
    const pauseTime = 4; // pause time in seconds for each item
    const scrollTime = 1; // scroll time in seconds between items
    const totalTime = (pauseTime + scrollTime) * itemCount;
    this.breakingNewsVertical.nativeElement.style.animation = `scroll-vertical ${totalTime}s infinite`;
  }
}
