<!-- <div class="row row-news">

    <div class="col-2 background-color-red" style="height: 3.2rem;"  > <p class="urgent-news-text"> عاجل</p> </div>

    <div class="col-10 breaking-news-container">
        <ul class="ul-news"   >
            <ng-container *ngFor="let news of breakingNewsItems; let i = index">
                <li class="li-news" >
                    {{ news }}
                </li>
                <ng-container *ngIf="i < breakingNewsItems.length - 1">
                    <li class="li-news logo-item">
                         <img src="assets/img/logo/4dmedia_logo_147311.png" style="    width: 25px;  height: 25px" alt="Logo" class="news-logo">
                    </li>
                </ng-container>

                 
                

            </ng-container>
        </ul>
    </div>
   <br/>
   
  </div> -->

 
  <!-- <div class="container news-main_row">
    <div class="row ">
      <div class="col-2 background-color-red" style="height: 3.2rem;"  > <p class="urgent-news-text"> عاجل</p> </div>
  
    <div class=" col-10 ticker-container">
      <div #ticker class="ticker">
        <div class="ticker-item" *ngFor="let news of breakingNewsItems; let i = index">
          {{ news }}
          <ng-container *ngIf="i < breakingNewsItems.length - 1">
          <img src="assets/img/logo/4dmedia_logo_147311.png" style="    width: 26px;  height: 26px; margin: 0 9px 0 6px;" alt="Logo" class="news-logo">
      </ng-container> 
      </div>
      </div>
    </div>
  </div>
  </div> -->


  <!-- <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
          <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1 news">
            <span class="d-flex align-items-center">&nbsp;Breaking News</span>
          </div>
          <marquee class="news-scroll" behavior="scroll" direction="right" scrollamount="7" onmouseover="this.stop();" onmouseout="this.start();">
            <ng-container *ngFor="let news of breakingNewsItems; let i = index">
              <a href="#">{{ news }}</a>
              <span *ngIf="i < breakingNewsItems.length - 1" class="dot"> • </span>
            </ng-container>
          </marquee>
        </div>
      </div>
    </div>
  </div> -->
  
  <div class="page-content page-container" id="page-content">
    <div class="  background-color-red" style="height: 3.2rem;"  > <p class="urgent-news-text">    آخر الاخبار </p> </div>
    <div>
      <ul class="ul-news1"   >
        <ng-container *ngFor="let news of breakingNewsItems; let i = index">
            <li class="li-news1" >
             <span style="color: #514f4f;">{{ news.time }}:</span>   {{ news.content }}
            </li>
        
             
            

        </ng-container>
    </ul>
    </div>
  </div>
 
 