import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { VideosComponent } from './components/pages/videos/videos.component';
import { VideoDetailsComponent } from './components/pages/video-details/video-details.component';
import { WeatherConditionsComponent } from './components/pages/weather-conditions/weather-conditions.component';
import { ExchangeRatesComponent } from './components/pages/exchange-rates/exchange-rates.component';
import { TobicDetailsComponent } from './components/pages/tobic-details/tobic-details.component';
import { TobicCategoryComponent } from './components/pages/tobic-category/tobic-category.component';
import { AuthorTobicComponent } from './components/pages/author-tobics/author-tobics.component';
import { CustomUrlSerializer } from './shared/custom-url-serializer';
import { BreakingNewsComponent } from './components/pages/breaking-news/breaking-news.component';
import { LiveComponent } from './components/pages/live/live.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent ,  data: { title: 'الرئيسية | 4Dpal' }},
    {path: 'about', component: AboutComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'category/:categoryId', component: CategoryComponent},
    {path: 'category/:title/:categoryId', component: CategoryComponent},
    {path: 'category', component: CategoryComponent},
    {path: 'news/:categoryTitle/:title/:newsId', component: NewsDetailsComponent},
    {path: 'videos', component: VideosComponent},
    {path: 'video-details/:id', component: VideoDetailsComponent},
    {path: 'WeatherConditions', component: WeatherConditionsComponent},
    {path: 'ExchangeRates', component: ExchangeRatesComponent},
    {path: 'tobic/:title/:id', component: TobicDetailsComponent},
    {path: 'tobics/:title/:id', component: TobicCategoryComponent},
    {path: 'authortobics/:title/:id', component: AuthorTobicComponent},
    {path: 'breakingnews', component: BreakingNewsComponent},
    {path: 'live', component: LiveComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    // providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
    exports: [RouterModule]
})
export class AppRoutingModule { }