import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/_models/Category';
import { MainService } from 'src/app/_services/main.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

   categoryListSource: Array<string> = [];
 
  categories$: Observable<any[]>;
  contactInfo$: Observable<any>;
  categoriesList: any[] = []; // To hold the data
  companyData:any;
  currentYear:number;
  constructor(  private mainService: MainService,   private sharedDataService: SharedDataService) { }


  ngOnInit(): void {
 
    this.currentYear = new Date().getFullYear();
    this.mainService.getActiveCategories().subscribe((data)=>{
      let dataList = data;
      if(dataList){
        this.categoriesList = dataList.slice(0,5);
      } 
 
    });
 
    this.sharedDataService.contactInfo$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
          this.companyData = dataList[0];
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )
 
  //   this.mainService.getCategoryALlData().subscribe((data) => {
  //     let dataSourse= JSON.parse(JSON.stringify(data));
  //      this.categoriesList= dataSourse.slice(0,5);
  //   });
  //   this.mainService.GetAllContactInfo().subscribe((data) => {
  //  let ContactInfo= JSON.parse(JSON.stringify(data));
  //    this.companyData= ContactInfo[0];
  //   });

  }

}
