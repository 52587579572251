import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';
import { News } from 'src/app/_models/News';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public categoryId:string;
  public categoryName:string;
 

  newsList: any[] = [];
  displayedNewsList: any[] = []; 
  firstLineNews:any[]=[];
  initialLoadDone = false; // Flag to check if initial load is don
  page = 1;
  limit = 30;
  total = 0;
  loading = false;
  hasMore = true; 
  readonly prefixUrl:string= environment.apiUrl+"/Image/view/";


  constructor(private titleService: Title,private activatedRoute: ActivatedRoute, private mainService: MainService, private sanitizer: DomSanitizer) {

   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.categoryId= params['categoryId'];
        this.categoryName= params['title'];
        
        this.titleService.setTitle(this.categoryName +" | "+"4Dpal");
        this.resetState();
        this.loadNews();
     
      });
           
  }
  resetState(): void {
    this.newsList = [];
    this.displayedNewsList = [];
    this.firstLineNews = [];
    this.page = 1;
    this.total = 0;
    this.loading = false;
    this.hasMore = true;
    this.initialLoadDone = false;
  }

  loadNews(): void {
    if (this.loading || !this.hasMore) return; 
    this.loading = true;
   
    this.mainService.getAllCategoryNews(this.categoryId, this.page.toString(), this.limit.toString()).subscribe(response => {
      
      if (this.page === 1) {
        this.newsList = response.data;
        } else {
            this.newsList = [...this.newsList, ...response.data];
        }
      this.total = response.total;
      this.page++;
      this.loading = false;
      this.hasMore = this.newsList.length < this.total; // Update hasMore flag

      if (!this.initialLoadDone) {
        // For the initial load, update displayedNewsList starting from index 3
        this.firstLineNews= this.newsList.slice(0,2);
        this.updateDisplayedNewsList();
        this.initialLoadDone = true; // Set flag to true after initial load
      } else {
        // For subsequent loads, set displayedNewsList directly
        this.displayedNewsList = this.newsList;
      }
    }, error => {
      console.error('Error loading news', error);
      this.loading = false;
    });
  }
  updateDisplayedNewsList(): void {
    this.displayedNewsList = this.newsList.slice(2);
  }
}
