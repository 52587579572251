import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedDataService } from 'src/app/_services/shared-data.service';
interface ExchangeRate {
  currency: string;
  rate: number;
}
@Component({
  selector: 'app-exchange-rates',

  templateUrl: './exchange-rates.component.html',
  styleUrl: './exchange-rates.component.scss'
})


export class ExchangeRatesComponent implements OnInit {
  exchangeRates$: Observable<any>;
  USDRates: any;
  EURRates: any;
  JODRates: any;
  EGPRates: any;
  goldRates:any;
  constructor( private sharedDataService: SharedDataService) { }

  ngOnInit(): void {
    this.fetchExchangeRates();
  }

  fetchExchangeRates(): void {
    this.sharedDataService.exchangeRates$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
           this.USDRates  = dataList.find(a=>a.base=="USD")?.rates; 
    
          this.EURRates  =dataList.find(a=>a.base=="EUR")?.rates;
       
          this.JODRates  =dataList.find(a=>a.base=="JOD")?.rates; 
       
          this.EGPRates  = dataList.find(a=>a.base=="EGP")?.rates;   
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )
    
  }
}
