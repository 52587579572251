import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacebookShareService {

  private fbInitialized: boolean = false;

  constructor() {
    this.loadFacebookSDK();
  }

  private loadFacebookSDK() {
    if (typeof (window as any).FB !== 'undefined') {
      this.fbInitialized = true;
      return;
    }

    (window as any).fbAsyncInit = () => {
      (window as any).FB.init({
        appId: '', // Leave empty if not using a Facebook App ID
        xfbml: true,
        version: 'v17.0' // Use the latest version
      });
      this.fbInitialized = true;
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  share(url: string ) {
    if (!this.fbInitialized) {
      console.error('Facebook SDK is not initialized.');
      return;
    }
  
    if (typeof (window as any).FB !== 'undefined') {
      (window as any).FB.ui({
        method: 'share',
        href: url
       
      }, (response: any) => {
        if (response && !response.error_message) {
          console.log('Sharing completed.');
        } else {
          console.error('Error while sharing:', response.error_message);
        //  alert('An error occurred while trying to share. Please check the console for more details.');
        }
      });
    } else {
      console.error('Facebook SDK is not loaded.');
     // alert('Facebook SDK is not loaded. Please check your configuration.');
    }
  }
}
