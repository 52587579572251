<section    [ngClass]="{'practice-area pt-50 pb-70': isLiveRoute}"   style="background-color: black;">
    <div  [ngClass]="{'container': isLiveRoute}"  >
        <h2 *ngIf="isLiveRoute" class="section-category-title" style="color: white;">
            البث الحي
        </h2>
        <div class="video-container-292">
            <div class="iframe-responsive-container">
                <iframe
                    class="live-container"
                    [src]="liveLink | safe"
                    allow="autoplay"
                    allowfullscreen
                    frameborder="0"
                    scrolling="no"
                ></iframe>
            </div>
        </div>
    </div>
</section>
