<div class="row  d-flex justify-content-center">
  <div class="card">
    

    <div class="card-body">
        <div class="card-title" style="display: flex;">
            <h3 style=" margin-left: 8px;  "> اسعار العملات </h3>
        <h4 style="    color: #037281;  ">مقابل الشيقل</h4>     
        </div>
        <div class="row">
          <div class="col-6">
            <div class="currency-item"  >
                <div class="currency-img">
                <img src="assets/img/icons/united-states.png" class="lz-init lazyloaded" style="height:2.2rem;"  alt="دولار أمريكي"></div>
                <div class="currency-info" style="margin: -4px 12px 3px 3px;">
                <div  class="currency-from"  >دولار أمريكي</div>
                <div class="currency-price">{{USDRates}} </div>
                </div>
                </div>
          </div>
          <div class="col-6">
            <div class="currency-item"  >
                <div class="currency-img">
                <img src="assets/img/icons/jordan.png" class="lz-init lazyloaded"  style="height: 2.2rem;" alt="دينار أردني"></div>
                <div class="currency-info" style="margin: -4px 12px 3px 3px;">
                <div  class="currency-from"  >دينار أردني</div>
                <div class="currency-price">{{JODRates}} </div>
                </div>
                </div>
          </div>
        </div>
        <div class="row">
            <div class="col-6"> 
                <div class="currency-item"  >
                    <div class="currency-img">
                    <img src="assets/img/icons/european-union.png"  style="height: 2.2rem;" class="lz-init lazyloaded"   alt="يورو"></div>
                    <div class="currency-info" style="margin: -4px 12px 3px 3px;">
                    <div  class="currency-from"  >يورو</div>
                    <div class="currency-price"> {{EURRates}}  </div>
                    </div>
                    </div>
            </div>
            <div class="col-6">
                <div class="currency-item"  >
                    <div class="currency-img">
                    <img src="assets/img/icons/egypt.png" class="lz-init lazyloaded"  style="height:2.2rem;"  alt="جنيه مصري"></div>
                    <div class="currency-info" style="margin: -4px 12px 3px 3px;">
                    <div  class="currency-from"  >جنيه مصري</div>
                    <div class="currency-price">{{EGPRates}}  </div>
                    </div>
                    </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-12">
                <div class="currency-item"  >
                    <div class="currency-img">
                    <img src="assets/img/icons/gold.png" class="lz-init lazyloaded"  style="height: 2.2rem;" alt="سعر اونصة الذهب"></div>
                    <div class="currency-info" style="margin: -4px 12px 3px 3px;">
                    <div  class="currency-from"  >سعر اونصة الذهب</div>
                    <div class="currency-price">2335$</div>
                    </div>
                    </div>
            </div>
           
          </div> -->
    </div>
  </div>

</div>
