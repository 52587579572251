<div class="case-details-img  pb-50">
    <div class="container">
      <div class="row">
        <div class="p-5 bg-white rounded shadow mb-5" style=" max-height: 100%;">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link f-bold active" (click)="selectTopic('1')" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true">مقالات</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link f-bold" id="two-tab" (click)="selectTopic('2')" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">ترجمات وردود</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link f-bold" id="three-tab" (click)="selectTopic('3')" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false">مدونات</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
              <br>
              <div class="row">
                <ng-container *ngFor="let item of TopicsData; let i = index;">
                  <div class="col-md-6">
                    <div class="card-article">
                      <img class="card-avatar-img" [src]="prefixUrl+item?.AuthorImage" aria-hidden="true">
                      <a class="card-article-link" routerLink="/tobic/{{ item?.SubjectTitle }}/{{ item?.SubjectId }}">
                        <div class="card-article-title"><span>{{item.SubjectTitle}}</span></div>
                        <div>
                          <span class="card-article-writer">{{item.AuthorName}}</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="more-link-container">
                <a routerLink="/tobics/مقالات/1" class="more-link">اقرأ المزيد</a>
              </div>
            </div>
            <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
              <br>
              <div class="row" *ngIf="firstTobicData != null">
                <div class="col-md-6 col-sm-12 card-article-link">
                  <div class="card" style="width: 100%; border: none !important;">
                    
                    <a routerLink="/tobic/{{ firstTobicData?.SubjectTitle }}/{{ firstTobicData?.SubjectId }}">
                      <div class="responsive-image">
                        <img class="card-img-top" style="max-height: 16rem;" [src]="prefixUrl+firstTobicData?.ImagePath" alt="Card image cap">
                      </div>
                      <div class="card-body" style="background: none">
                        <h5 class="card-text news-content" style="font-size: larger;">
                          {{firstTobicData?.SubjectTitle}}
                        </h5>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-6  col-sm-12  card-article-link">
                  <ng-container *ngFor="let item of TopicsData; let i = index;">
                    <div class="card" style="border: none !important;">
                      <a routerLink="/tobic//{{ item?.SubjectTitle }}/{{ item?.SubjectId }}">
                        <div class="card-body" style="background: none">
                          <div class="row">
                            <div class="col-lg-5 col-md-5 col-xs-12 card-img-new">
                              <img style="max-height: 6rem;" class="img-fluid responsive-image" [src]="prefixUrl+item?.ImagePath" alt="Blog" />
                            </div>
                            <div class="col-lg-7 col-md-7 col-xs-12 card-text">
                              <h6 class="news-content" style="font-weight: bold">
                                {{item.SubjectTitle}}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="more-link-container">
                <a routerLink="/tobics/ترجمات وردود/2" class="more-link">اقرأ المزيد</a>
              </div>
            </div>
            <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
              <br>
              <div class="row" *ngIf="firstTobicData != null">
                <div class="col-md-6 col-sm-12  card-article-link">
                  <div class="card" style="width: 100%; border: none !important;">
                    <a routerLink="/tobic/{{ firstTobicData?.SubjectTitle }}/{{ firstTobicData?.SubjectId }}">
                      <div class="responsive-image">
                        <img class="card-img-top" style="max-height: 16rem;" [src]="prefixUrl+firstTobicData?.ImagePath" alt="Card image cap">
                      </div>
                      <div class="card-body" style="background: none">
                        <h5 class="card-text news-content" style="font-size: larger;">
                          {{firstTobicData?.SubjectTitle}}
                        </h5>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 card-article-link">
                  <ng-container *ngFor="let item of TopicsData; let i = index;">
                    <div class="card" style="border: none !important;">
                      <a routerLink="/tobic/{{ item?.SubjectTitle }}/{{ item?.SubjectId }}">
                        <div class="card-body" style="background: none">
                          <div class="row">
                            <div class="col-lg-5 col-md-5 col-xs-12 card-img-new">
                              <img style="max-height: 6rem;" class="img-fluid responsive-image" [src]="prefixUrl+item?.ImagePath" alt="Blog" />
                            </div>
                            <div class="col-lg-7 col-md-7 col-xs-12 card-text">
                              <h6 class="news-content" style="font-weight: bold">
                                {{item.SubjectTitle}}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="more-link-container">
                <a routerLink="/tobics/مدونات/3" class="more-link">اقرأ المزيد</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  