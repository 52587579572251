<section class="practice-area pt-50 pb-70">
    <div class="container">

       
            <h2 class="section-category-title">
              {{categoryName}}
            </h2>
            
        <!--<div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>-->

        <div class="row  border-b">
            <ng-container  *ngIf="firstLineNews && firstLineNews.length > 0"  >
            <div class="col-sm-6 col-lg-8">
                <a  routerLink="/news/{{categoryName}}/{{firstLineNews[0].NewsTitle}}/{{firstLineNews[0].NewsId}}"  >
                <div class="practice-item">
                    <div  >
                        
                        <img  class="img-fluid"  style="width: 100%;   max-height: 27rem;" [src]="prefixUrl +firstLineNews[0]?.ImagePath"  alt="Shape"   >
                    </div>
                    <h3> {{firstLineNews[0]?.NewsTitle}}</h3>
                    <p>  {{firstLineNews[0]?.NewsIntroduction}}</p> 
                   
                    
                </div>
            </a>
            </div>

            <div class="col-sm-6 col-lg-4"  *ngIf=" firstLineNews.length >  1">
                <a routerLink="/news/{{categoryName}}/{{firstLineNews[1].NewsTitle}}/{{firstLineNews[1]?.NewsId}}">

                <div class="practice-item">
                    <div  >
                        <img  class="news-cover-img" [src]="prefixUrl +firstLineNews[1]?.ImagePath"  alt="Shape" width= "100%;"   height= "238px;">
                    </div>
                    <h3 class="news-title" style="  line-height: 32px;  "> {{firstLineNews[1]?.NewsTitle}}</h3>
                    <p>  {{firstLineNews[1]?.NewsIntroduction}}</p> 
                </div>
                </a>
            </div>
            

        </ng-container>


 
        </div>
        <div class="row">
            <ng-container *ngFor="let subItem of displayedNewsList">

            <div class="col-sm-6 col-lg-4">
                <a  routerLink="/news/{{categoryName}}/{{categoryName}}/{{subItem.NewsId}}" >

                <div class="practice-item">
                    <div  >
                        <img class="news-cover-img" [src]="prefixUrl +subItem?.ImagePath"   alt="Shape"  width="238px;">

                    </div>
                    <h5 class="news-title" style="  line-height: 32px;  ">  {{subItem.NewsTitle}}</h5>
                    <p>  {{subItem.NewsIntroduction}}</p>
                   
                </div>
                </a>
            </div>
            
    </ng-container>
        </div>
    </div>
    <div *ngIf="loading">
        <!-- Show a loading spinner or message -->
        Loading...
      </div>
</section>
<div
class="search-results"
infiniteScroll
[infiniteScrollDistance]="2"
[infiniteScrollThrottle]="30"
(scrolled)="loadNews()"
></div>
 