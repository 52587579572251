import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { VideosComponent } from './components/pages/videos/videos.component';
import { DatePipe } from '@angular/common'
import { VideoDetailsComponent } from './components/pages/video-details/video-details.component';
import { SafePipe } from './shared/safe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WeatherConditionsComponent } from './components/pages/weather-conditions/weather-conditions.component';
import { ExchangeRatesComponent } from './components/pages/exchange-rates/exchange-rates.component';
import { TobicDetailsComponent } from './components/pages/tobic-details/tobic-details.component';
import { BreakingNewsComponent } from './components/pages/breaking-news/breaking-news.component';
import { TobicCategoryComponent } from './components/pages/tobic-category/tobic-category.component';
import { AuthorTobicComponent } from './components/pages/author-tobics/author-tobics.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TobicsComponent } from './components/pages/tobics/tobics.component';
import { SharedDataService } from './_services/shared-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { LiveComponent } from './components/pages/live/live.component';
import { NewsShareComponent } from './components/pages/news-share/news-share.component';
 
 
import { FacebookShareService } from './shared/facebook-share.service';

 
 


 
export function initializeApp(dataService: SharedDataService): () => Promise<void> {
  return (): Promise<void> => new Promise((resolve, reject) => {
    try {
      dataService.initializeData();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    AboutComponent,


  
    FaqComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ContactComponent,
    CategoryComponent,
    NewsDetailsComponent,
    VideosComponent,
    VideoDetailsComponent,
    WeatherConditionsComponent,
    ExchangeRatesComponent,
    SafePipe,
    TobicDetailsComponent,
    BreakingNewsComponent,
    TobicCategoryComponent,
    AuthorTobicComponent,
    TobicsComponent,
    LiveComponent,
    NewsShareComponent
 
  ],
  imports: [
    
    BrowserModule,
    AppRoutingModule ,
    HttpClientModule,
    BrowserAnimationsModule,
    
    NgbModule ,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
 
   
 
   
 
 
     
  ],
  providers: [DatePipe,
    SharedDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [SharedDataService],
      multi: true
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Le9DhcqAAAAAMTvbwyDvm6a3Spm525ovR9LNPfV' } , // Provide your site key here
    FacebookShareService, provideClientHydration()

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
