import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from './_services/local-storage.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,private localStorageService: LocalStorageService) {
    }

    ngOnInit(){
        this.recallJsFuntions();
 
   
        //   this.runDailyTask(() => {
        //     console.log('Running daily task...');
        //     this.localStorageService.fetchExchangeRates();
        //   }, 0, 0, 0);
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.loader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    runDailyTask(task: () => void, hour: number = 0, minute: number = 0, second: number = 0) {
        const lastTaskDate = localStorage.getItem("fetchExchangeRatesDate");
        const now = new Date();
        const scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute, second);
    
        // Check if task has already been run today
        if (!lastTaskDate || new Date(lastTaskDate) < scheduledTime) {
          // Run the task
          task();
    
          // Store today's date as last execution date
          localStorage.setItem("fetchExchangeRatesDate", now.toISOString());
        }
      }
}