import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';
import { environment } from 'src/environments/environment.prod';
import { Title } from '@angular/platform-browser';
import { FacebookShareService } from 'src/app/shared/facebook-share.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  public newsId: string;
  public newsData: any;
  public newsReletedList: any[] = [];
  public videoLink: string = '';
  newsURL: string;
  public categoriesList: Array<any> = [];
  categoryName: string;
  ads4Data: any;
  readonly prefixUrl: string = environment.apiUrl + "/Image/view/";
  readonly adsPrefixUrl: string = environment.apiUrl + "/Image/a/";
  title: string;
  description: string;
  imageUrl: string;

  constructor(private metaService: Meta, private facebookShareService: FacebookShareService, private router: Router, private activatedRoute: ActivatedRoute, private mainService: MainService, private titleService: Title, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.newsId = params['newsId'];
      this.categoryName = params['categoryTitle'];
      this.mainService.getNewsWithRelatedById(this.newsId).subscribe(data => {
        this.newsData = data.news;
        this.titleService.setTitle(this.newsData.NewsTitle + " | " + "4Dpal");

        if (this.newsData.NewsVideoURL) {
          let linkCode = this.newsData.NewsVideoURL.split("=")[1];
          this.videoLink = "https://www.youtube.com/embed/" + linkCode;
        }
        this.title=  this.newsData.NewsTitle;
        this.description= this.newsData.NewsIntroduction;
        this.imageUrl = this.prefixUrl + this.newsData?.ImagePath;

        this.newsReletedList = data.relatedNews;
      
       
      });
    });
 
  }
 
  // updateMetaTags() {
  //   let route = this.router.url;
  //   let newsURL = environment.domainURL + route;
  //   const imageUrl = this.prefixUrl + this.newsData?.ImagePath;
   
  //     this.metaService.updateTag({ name: 'description', content: this.newsData.NewsTitle });
  //     this.metaService.updateTag({ property: 'og:title', content: this.newsData.NewsTitle });
  //     this.metaService.updateTag({ property: 'og:description', content: this.newsData.NewsIntroduction });
  //     this.metaService.updateTag({ property: 'og:image', content: imageUrl});
  //     this.metaService.updateTag({ property: 'og:url', content: window.location.href });
 
  
  // }
  
}
