<div class="page-content page-container" id="page-content">
    <div class=" ">
        <div class="row   d-flex justify-content-center">
<div class="col-12 grid-margin stretch-card">
              <!--weather card-->
              <div class="card card-weather">
                <div class="card-body" style=" height: 17rem; ">
                  <div class="weather-date-location" style="text-align: left;">
                    <h3 style="    color: #037281;  ">{{ currentDate  }}</h3>
                    <!-- <p class="text-gray">
                     
                      <span class="weather-location">القدس</span>
                    </p> -->

                    
                    
                    <div class="header-weather">
                      <div class="header-weather-city" style="margin-top: 11px; margin-right: 68%;">
                        

                          <select name="city" id="citySelect"   style="background-color: white !important;" class="weather-border weather-cities-list"   (change)="onCityChange($event)"  >
                              <option
                                  selected="selected"
                                  value="jerusalem"
                              >
                                  القدس
                              </option>
                              <option  value="ramallah">رام الله</option>
                              <option value="jericho">أريحا</option>
                              <option value="bethlehem">بيت لحم</option>
                              <option value="beersheba">بئر السبع</option>
                              <option value="gaza">غزة</option>
                              <option value="hebron">الخليل</option>
                              <option value="jenin">جنين</option>
                              <option value="tubas">طوباس</option>
                              <option value="nablus">نابلس</option>
                              <option value="rafah">رفح</option>
                              <option value="khan-yunus">خان يونس</option>
                              <option value="qalqilya">قلقيلية</option>
                              <option value="tulkarm">طولكرم</option>
                              <option value="salfit">سلفيت</option>
                          </select>
                       
                      </div>
                  </div>
                  </div>
                  <div class="weather-data d-flex">
                    <div class="mr-auto">
                      <h4 class="display-3">{{weatherDegree}}
                        <span class="symbol">&deg;</span>C</h4>
                      <p>
                        {{WeatherStatus}}
                      </p>
                    </div>
                    <div class="mr-auto">
                      <h4 class="display-3">{{minDegree}}
                        <span class="symbol">&deg;</span>C</h4>
                      <p>
                        الصغرى
                      </p>
                    </div>
                    <div class="mr-auto">
                      <h4 class="display-3">{{maxDegree}}
                        <span class="symbol">&deg;</span>C</h4>
                      <p>
                        الكبرى
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
              <!--weather card ends-->
            </div>
            </div>
            </div>
            </div>


