import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/_services/main.service';

@Component({
  selector: 'app-live',
 
  templateUrl: './live.component.html',
  styleUrl: './live.component.scss'
})
export class LiveComponent  implements OnInit  {
  public liveLink:string= '';
  isLiveRoute: boolean = false;

  constructor(private mainService: MainService,private router: Router, private activatedRoute: ActivatedRoute ){
    
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.isLiveRoute = this.router.url.includes('/live');
    });

    // OR using ActivatedRoute for more dynamic segment matching
    this.activatedRoute.url.subscribe((segments) => {
      this.isLiveRoute = segments.some(segment => segment.path === 'live');
    });
 
    this. getLiveVideo();

}

getLiveVideo(): void {
    this.mainService.getLiveVideo().subscribe(
      data => {
        const dataReturned = JSON.parse(JSON.stringify(data));
         
         
         this.liveLink=   dataReturned?.LiveVideoUrl;
        // Set animation after breaking news items are loaded
        //this.setAnimation();
      },
      error => {
        // Handle errors
        console.log("getLiveVideor", error);
      }
    );
  }
}
